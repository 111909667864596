import { Box, Hidden } from "@mui/material";
import "../../scss/components/header.scss";
import useTheme from "../../hooks/useTheme";
import { FC, useContext } from "react";
import { SettingContext } from "../../states";
import { SettingContextType } from "../../states/contexts/SettingContext";
import { HomeIcon, ProfileIcon, StatisticsIcon } from "../../constants/icons";
import { StatisticDialog, WordleTimer } from "../../screens/Wordle/common";
import {
  StatisticContext,
  StatisticContextType,
} from "../../states/contexts/StatisticContext";

type IProps = {
  isCompleteSplashScreen: boolean;
};

const Header: FC<IProps> = (props) => {
  const { isCompleteSplashScreen } = props;
  const { mode } = useTheme();
  const settingContext: SettingContextType = useContext(SettingContext);
  const statisticContext: StatisticContextType = useContext(StatisticContext);

  return (
    <Box
      id="header"
      sx={{ justifyContent: { xs: "end", sm: "space-between" } }}
    >
      <Box className="logo">
        <img
          src={`./assets/images/wordle-logo-${mode}.png`}
          alt="wordle-logo"
          height={22}
        />
      </Box>

      <Box className="menu-icons" sx={{ justifyContent: "end" }}>
        <Box id="header-timer">
          <Hidden smUp>
            <WordleTimer
              isHeader
              isCompleteSplashScreen={isCompleteSplashScreen}
            />
          </Hidden>
        </Box>

        <Box
          id="home-icon"
          onClick={() => {
            try {
              BundleAndroid.navigateToHomePage();
            } catch (error) {
              console.log("error", error);
            }
            try {
              window.webkit.messageHandlers.navigateToHomePage.postMessage("a");
            } catch (error) {
              console.log("error", error);
            }
          }}
        >
          <HomeIcon />
        </Box>

        <Box
          id="statistic-icon"
          onClick={() => {
            statisticContext.setIsOpenStatisticDialog(true);
          }}
        >
          <StatisticsIcon />
        </Box>
        <Box
          id="profile-icon"
          onClick={() => {
            settingContext.setIsOpenSettingDialog(true);
          }}
        >
          <ProfileIcon />
        </Box>
      </Box>

      {statisticContext.isOpenStatisticDialog && <StatisticDialog />}
    </Box>
  );
};

export default Header;
