import { Box, Typography } from "@mui/material";
import { Fragment } from "react";
import { SwitchButton } from "../../../../../../components";
import useThemeMode from "../../../../../../hooks/useTheme";
import { ThemeMode } from "../../../../../../utils/enums";
import useLocales from "../../../../../../hooks/useLocales";
import { useAppDispatch } from "../../../../../../app/hooks";
import { changeTheme } from "../../../../../../features/theme/ThemeSlice";

const SettingThemeBlock = () => {
  const dispatch = useAppDispatch();
  const { translate } = useLocales();
  const { mode }: any = useThemeMode();

  const changeThemeHandler = () => {
    dispatch(changeTheme());
    try {
      BundleAndroid?.setTheme(mode === ThemeMode.dark ? "#000000" : "#f4f5f6");
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Fragment>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
          {translate("dark-theme")}
        </Typography>
        <SwitchButton
          name="themeMode"
          checked={mode === ThemeMode.dark}
          onChange={changeThemeHandler}
        />
      </Box>
    </Fragment>
  );
};

export default SettingThemeBlock;
