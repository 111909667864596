import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { CloseIcon } from "../../constants/icons";
import { LineerProgressBar } from "../../components";
import i18n from "../../i18n";
import "../../scss/screens/advert-screen.scss";

interface VisionProScreenProps {
  isVisible: boolean;
  handleVisibility: (visibility: boolean) => void;
}

export const VisionProScreen: FC<VisionProScreenProps> = ({
  isVisible,
  handleVisibility,
}) => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (isVisible && progress < 100) {
      const interval = setInterval(() => {
        setProgress((curr) => curr + 1);
      }, 100);

      return () => {
        clearInterval(interval);
      };
    }
    if (progress >= 100 && !isVisible) {
      setProgress(0);
    }

    if (progress >= 100 && isVisible) {
      handleVisibility(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, progress]);

  const closeHandler = () => {
    handleVisibility(false);
    setProgress(0);
  };

  const discoverHandler = () => {
    // window.location.href = "https://visionpro.com";
  };

  return (
    <Container
      id="advert"
      maxWidth={false}
      sx={{
        p: 0,
        position: "fixed",
        inset: 0,
        zIndex: 1000,
        backgroundColor: "black",
        display: isVisible ? "block" : "none",
      }}
    >
      <Box sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
        <LineerProgressBar
          sx={{
            "& .MuiLinearProgress-bar": {
              background: "#31AC37",
            },
            borderRadius: 0,
            height: 10,
          }}
          value={progress}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: 0,
          right: 0,
          padding: "0 32px",
          transform: "translateY(-50%)",
          maxWidth: "630px",
          margin: "0 auto",
        }}
      >
        <img src={`/assets/images/vision-pro.png`} alt="" width="100%" />
      </Box>
      <IconButton
        aria-label="close"
        onClick={closeHandler}
        sx={{
          position: "absolute",
          top: 24,
          right: 24,
          p: "14px",
          backgroundColor: "#0E0E0E",
          border: "1px solid rgba(163, 169, 176, 0.2)",
        }}
      >
        <CloseIcon height={18} width={18} color="#424242" />
      </IconButton>
      <Box
        sx={{
          position: "absolute",
          bottom: "10%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Button id="discover" sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "50px",
            minWidth: "208px",
        }} variant="contained" onClick={discoverHandler}>
          <Typography className="text">{i18n.t("discover_now")}</Typography>
        </Button>
      </Box>
    </Container>
  );
};
