import { GameState, StatisticTabs } from "../utils/enums";
import { LETTER_LENGTH } from "../constants/settings";
import { useCallback, useContext, useEffect, useState } from "react";
import { keyboardKeysTr, keyboardKeysDe, keyboardKeysEn } from "../screens/Wordle/common/Keyboard";
import { AddGuessResponse, WordleStateLocale, wordleStore } from "../features/wordle/WordleStore";
import useLocales from "./useLocales";
import useAlert from "./useAlert";
import { getErrorNotification, getGameEndNotification } from "../utils/methods";
import { GetGameNotificationMessageRes } from "../interfaces";
import i18n from "../i18n";
import { SettingContext } from "../states";
import { SettingContextType } from "../states/contexts/SettingContext";
import { StatisticContext, StatisticContextType } from "../states/contexts/StatisticContext";

export const useGuess = () => {
    const wordleState: WordleStateLocale = wordleStore();
    const { translate, currentLang } = useLocales();
    const [guess, setGuess] = useState<string>("");
    const { showAlert } = useAlert();
    const { isLoadingComputeGuess, setIsLoadingComputeGuess }: SettingContextType = useContext(SettingContext);
    const { setIsOpenStatisticDialog, setSelectedTab }: StatisticContextType = useContext(StatisticContext);

    const keyboardKeys: string[][] = currentLang.value === "TR" ? keyboardKeysTr : currentLang.value === "EN" ? keyboardKeysEn : keyboardKeysDe;

    const addGuessLetter = useCallback(
        async (letter: string): Promise<void> => {
            if (wordleState[currentLang.value].gameState !== GameState.PLAYING || (guess.length === 0 && letter === "Enter") || isLoadingComputeGuess)
                return;

            let newGuess: string = guess;

            if (
                !keyboardKeys
                    .flat()
                    .map((key: string) => key.toLocaleUpperCase())
                    .includes(letter.toLocaleUpperCase())
            )
                return;

            if (letter.length === 1 && newGuess.length !== LETTER_LENGTH) {
                newGuess = newGuess + letter;
            }

            switch (letter) {
                case "Backspace":
                    newGuess = newGuess.slice(0, -1);
                    break;
                case "Enter":
                    if (newGuess.length === LETTER_LENGTH) {
                        try {
                            setIsLoadingComputeGuess(true);
                            const res: AddGuessResponse = await wordleState.addGuess(newGuess, currentLang.value);

                            if (res.isGameEnded) {
                                const notificationResponse: GetGameNotificationMessageRes = getGameEndNotification(res.guessLength);

                                const message: string =
                                    res.gameState === GameState.LOST && res.word
                                        ? `${notificationResponse.message}. ${i18n.t("current_day_word")} ${res.word}`
                                        : notificationResponse.message;

                                setTimeout(() => {
                                    showAlert(message, 2000, notificationResponse.icon, () => {
                                        setSelectedTab(StatisticTabs.GameSummary);
                                        setIsOpenStatisticDialog(true);
                                    });
                                    }, 1500);
                            }
                            newGuess = "";
                        } catch (err) {
                            const notificationResponse: GetGameNotificationMessageRes = getErrorNotification(err);
                            showAlert(notificationResponse.message, 3000, notificationResponse.icon);
                        } finally {
                            setIsLoadingComputeGuess(false);
                        }
                    } else {
                        showAlert(translate("not_enought_letter"), 3000, "🤔");
                    }
                    break;
            }

            if (newGuess.length <= LETTER_LENGTH) {
                setGuess(newGuess);
            }
        },
        [wordleState, currentLang, guess, isLoadingComputeGuess, setIsLoadingComputeGuess, showAlert, translate]
    );

    const onKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (wordleState[currentLang.value].gameState !== GameState.PLAYING || isLoadingComputeGuess) return;

            const letter: string = event.key;

            addGuessLetter(letter);
        },
        [wordleState, addGuessLetter, currentLang, isLoadingComputeGuess]
    );

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [onKeyDown]);

    return { guess, setGuess, addGuessLetter };
};
