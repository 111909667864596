import { useEffect, useRef, useState } from "react";
import { StatisticContext } from "../contexts/StatisticContext";
import { GameState, StatisticTabs } from "../../utils/enums";
import { WordleStateLocale, wordleStore } from "../../features/wordle/WordleStore";
import { useLocales } from "../../hooks";

const StatisticProvider = (props: any) => {
    const {isCompleteSplashScreen} = props;
    
    const hasDialogBeenShown = useRef(false);

    
    const [isOpenStatisticDialog, setIsOpenStatisticDialog] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<StatisticTabs>(StatisticTabs.GameSummary);

    const wordleState: WordleStateLocale = wordleStore();

    const { currentLang } = useLocales();

    useEffect(() => {
      if (
        !hasDialogBeenShown.current &&
        isCompleteSplashScreen &&
        !!wordleState[currentLang.value]
      ) {
        hasDialogBeenShown.current = true;
        if (wordleState[currentLang.value].gameState === GameState.WON) {
          setTimeout(() => {
            setIsOpenStatisticDialog(true);
          }, 1500);
        }
      }
    }, [wordleState, currentLang.value, isCompleteSplashScreen]);

    return (
        <StatisticContext.Provider value={{ isOpenStatisticDialog, setIsOpenStatisticDialog, selectedTab, setSelectedTab }}>
            {props.children}
        </StatisticContext.Provider>
    );
};

export default StatisticProvider;
