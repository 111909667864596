import { create } from "zustand";
import { persist } from "zustand/middleware";
import { PERSIST_AUTH_VERSION } from "../../constants/versions";

export type AuthState = {
  isAuthenticate: boolean;
  isPremium: boolean;
  isAnonymousUser: boolean;
  accessToken?: string;
  deviceId?: string;
  bundleId?: string;
  userName: string;
  image: string;
  useLogin: (accessToken: string, bundleId: string, deviceId: string) => void;
  useLogout: () => void;
  useAnonymousLogin: (bundleId: string, deviceId: string) => void;
  changePremium: () => void;
  setUserInfo: (userName: string, image: string, isPremium: boolean) => void;
};

export const authInitial = {
  isAuthenticate: false,
  isPremium: false,
  isAnonymousUser: false,
  accessToken: undefined,
  deviceId: undefined,
  bundleId: undefined,
  userName: "",
  image: "",
};

export const authStore = create<AuthState>()(
  persist(
    (set, get) => ({
      ...authInitial,
      useLogin: (accessToken: string, bundleId: string, deviceId: string) => {
        set(() => ({
          ...authInitial,
          accessToken,
          bundleId,
          isAuthenticate: true,
          deviceId,
        }));
      },
      useLogout: () => {
        set(() => ({
          ...authInitial,
        }));
      },
      useAnonymousLogin: (bundleId: string, deviceId: string) => {
        set(() => ({
          ...authInitial,
          isAuthenticate: true,
          isAnonymousUser: true,
          bundleId,
          deviceId,
        }));
      },
      changePremium: () => {
        try {
          BundleAndroid.navigateToPremium();
        } catch (error) {
          console.log("error", error);
        }
        try {
          window.webkit.messageHandlers.navigateToPremium.postMessage("a");
        } catch (error) {
          console.log("error", error);
        }
        set(() => ({
          isPremium: !get().isPremium,
        }));
      },
      setUserInfo: (userName: string, image: string, isPremium: boolean) => {
        set(() => ({ ...get(), userName, image, isPremium }));
      },
    }),
    {
      name: "auth",
      version: PERSIST_AUTH_VERSION,
      migrate: (persistState: any, versionNumber: number) => {
        if (versionNumber !== PERSIST_AUTH_VERSION) {
          return { ...authInitial, ...persistState };
        }

        return persistState;
      },
    }
  )
);
